.homeSideBarScrollbar::-webkit-scrollbar {
  width: 12px;
}

.homeSideBarScrollbar::-webkit-scrollbar-track {
  background: #63b4f6;
  border-radius: 8px;
}

.homeSideBarScrollbar::-webkit-scrollbar-thumb {
  background: #42a4f5;
  border-radius: 8px;
}
